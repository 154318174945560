<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import axios from "axios";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { ref,  onBeforeMount  } from 'vue'
import { globals } from "@/main.js";
const startdate = ref(null)
const enddate = ref(null)
const json = ref({})
const loading = ref(false)

onBeforeMount (() => {
  generateReport()
})
function generateReport() {
      loading.value = true
      json.value = {};
      let token = localStorage.getItem("token");
      const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      
        const body = {
        in_args: JSON.stringify({
          starttime: startdate.value ? moment(startdate.value).format("YYYY-MM-DD") : null,
          endtime: enddate.value ? moment(enddate.value).format("YYYY-MM-DD") : null,
        })
      };
      axios
        .post(`${base_url}/reports/dbase/balance-cards`, body, { headers })
        .then(resp => {
          json.value = resp.data
          loading.value = false
        })
        .catch((e) => {
          globals.$toast("Ошибка", e.message, 5000, 'error')
          loading.value = false
        })

    }
</script>
<template>
   <div class="container">
    <div class="offset-md-1 col-md-7 show-font">
      <h3 class="mb-4">Расход часов по обращениям</h3>
      <form enctype="multipart/form-data">
        <div class="form-row">
          <div class="show-font input-group" id="calendar">
            <span class="col-sm-1 input-group-text show-font">От:</span>

            <VueDatePicker
              v-model="startdate"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              format="dd/MM/yyyy HH:mm"
              class="form-control"
            ></VueDatePicker>

            <span class="col-sm-1 input-group-text show-font">До:</span>

            <VueDatePicker
              v-model="enddate"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              format="dd/MM/yyyy HH:mm"
              class="form-control"
            ></VueDatePicker>
            <button class="btn btn-success" @click.prevent="generateReport()">Показать отчёт</button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="Object.keys(json).length">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="card text-center m-4">
                <div class="card-header h3">Основная информация</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4">
                      <h5 class="card-title display-6">{{json.summary.covered}}</h5>
                      <p class="card-text">Израсходовано часов из контракта</p>
                    </div>
                    <div class="col-4">
                      <h5 class="card-title display-6">{{json.summary.credit}}</h5>
                      <p class="card-text">Израсходовано, но не покрыто контрактом</p>
                    </div>
                    <div class="col-4">
                      <h5 class="card-title display-6">{{json.summary.balance}}</h5>
                      <p class="card-text">Остаток доступных часов по контракту</p>
                    </div>
                  </div>
                </div>
                <div
                  class="card-footer text-muted"
                >В период с {{moment(json.range.starttime).format('D.MM.YYYY')}} по {{moment(json.range.endtime).format('D.MM.YYYY')}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card text-center m-4">
                <div class="card-header h3">Список контрактных карт</div>
                <div class="card-body">
                  <div class="card-group">
                    <div
                      class="card text-center"
                      v-for="card in json.cards"
                      :key="card"
                      style="min-width: 300px"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-6">
                            <h5 class="card-title">{{card.income}}</h5>
                            <p class="card-text">Приобретено</p>
                          </div>
                          <div class="col-6">
                            <h5 class="card-title">{{card.balance}}</h5>
                            <p class="card-text">Остаток</p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card-footer text-muted"
                      >В период с {{moment(card.startdate).format('D.MM.YYYY')}} по {{moment(card.enddate).format('D.MM.YYYY')}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card text-center m-4">
                <div class="card-header h3">Список обращений</div>
                <div class="card-body" id="accordion">
                  <div v-for="(customer, index) in json.efforts.map(e=>e.customer).filter((el,inx,arr) => {return arr.indexOf(el) == inx})" :key="customer" >
                    <div class="card-header" :id="'heading'+index">
                      <h5 class="mb-0">
                        <div class="d-grid gap-2">
                          <button
                            class="btn dropdown-toggle collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#collapse'+index"
                            aria-expanded="false"
                            :aria-controls="'collapse'+index"
                            style="text-align: -webkit-left;"
                          >
                            {{customer}}
                          </button>
                        </div>
                      </h5>
                    </div>
                    
                    <div :id="'collapse'+index" class="collapse" :aria-labelledby="'heading'+index" data-parent="#accordion">
                      <div class="card-body">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">ID обращения</th>
                              <th scope="col">ID задачи</th>
                              <th scope="col">Затраты</th>
                              <th scope="col">Дата окончания</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="effort in json.efforts.filter(elem=>elem.customer === customer)" :key="effort">
                              <td>{{effort.ticket_id}}</td>
                              <td>{{effort.task_id}}</td>
                              <td>{{effort.efforts}}</td>
                              <td>{{moment(effort.enddate).format('D.MM.YYYY')}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center col col-sm-9">
      <div class="spinner-border text-primary mt-5" role="status" v-if="!Object.keys(json).length && loading">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<style>
.dp__input {
  border: 0px solid;
}

.dropdown-toggle::after {
  margin-left: 0.5em;
    vertical-align: 0.1em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-toggle.collapsed::after {
  margin-left: 0.5em;
    vertical-align: 0.1em;
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}
</style>