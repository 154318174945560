<script setup>
import { defineProps, defineEmits, ref, onMounted, reactive, watch } from "vue";
import { props as propsModel } from "./models/props.js";
import { sortBy, filterBy } from "./functions.js";
import axios from "axios";
import { useStore } from "vuex";

const store = useStore();

// Initializing ToolTips
import bootstrap from "bootstrap/dist/js/bootstrap.js";

//Search part
import searchBox from "./utils/SearchWebPart";

const props = defineProps(propsModel);
const emit = defineEmits(["pushData"]);

const data = reactive({});
const buttonText = ref("");

//Search part
const filtredData = ref(data);

// Axios part
const token = localStorage.getItem("token");
const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`
};

const user = reactive({});

watch(props, (oldValue, newValue) => {
   if(props.hide && !props.preserveValue){
    emit('pushData', {id: props.id, value: ""})
    buttonText.value = null;
  }
  if (newValue != "" && newValue != null && newValue != undefined) {
    if (
      newValue.masterComponentValue === "" ||
      newValue.masterComponentValue === null ||
      newValue.masterComponentValue === undefined
    ) {
      data.value = {};
      emit('pushData', {id: props.id, value: ""})
      buttonText.value = null;
    } else {
      fetchData(newValue.masterComponentValue);
    }
  }
});

watch(user, (oldval, newval) => {
  if ("id" in newval.value) {
    fetchData(newval.value.id);
  }
});

watch(data, () => {
  if ("defaultValue" in props.options) {
    autoSelectElemet();
  }
});

onMounted(() => {
  store.dispatch("getUser").then(resp => (user.value = resp));

  // Initializing ToolTips
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList];
  tooltipList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
  //

  if (props.options.API.includes("${user_id}")) {
    // fetchData(user.value.id);
  }
  // axios
  //   .get(`${process.env.VUE_APP_BASE_API}/api/v1/users/get-current-user`, {
  //     headers
  //   })
  //   .then(resp => {
  //     user.value = resp.data;
  //   });
  // } else
  else if (props.masterComponentValue === undefined) {
    fetchData();
  } else {
    console.log("no referComponents");
  }
});

function fetchData(masterValue) {
  let url = `${base_url}${props.options.API}`;

  if (masterValue) {
    url = url.replace(/\$\{.*\}/, masterValue);
  }

  const value = props.options.valueColumn;
  const text = props.options.textColumn;

  axios.get(url, { headers }).then(resp => {
    data.value = resp.data.map(e => {
      return { text: e[text], value: e[value], is_moderator: e.is_moderator };
    });
    if (props.filter.filterBy != "" && props.filter.value != "") {
      filterBy(data, props.filter);
    }
    sortBy(data, props.sort, "text");
    if ("defaultValue" in props.options) {
      if (props.options.defaultValue) {
        const elems = data.value.filter(
          e => e.value === props.options.defaultValue
        );
        if (elems.length > 0) {
          buttonText.value = data.value.filter(
            e => e.value === props.options.defaultValue
          )[0].text;
        } else {
          buttonText.value = "";
        }
      } else {
        buttonText.value = "";
      }
    }
  });
}

function autoSelectElemet() {

  switch(props.options.defaultValue) {
    case "current":
    axios
      .get(`${process.env.VUE_APP_BASE_API}/api/v1/users/get-current-user`, {
        headers
      })
      .then(resp => {
        // let user_ = resp.data;
        let user__ = data.value.filter(u => u.value === resp.data.id);
        if (user__.length > 0) {
          buttonText.value = user__[0].text;
          emit("pushData", { id: props.id, value: user__[0].value.toString() });
        } else {
          buttonText.value = "";
          emit("pushData", { id: props.id, value: "" });
        }
      });
    break
    
    case "first":
      buttonText.value = data.value[0].text;
      emit("pushData", { id: props.id, value: data.value[0].value.toString() });
    break
    
    case null:
    case "":
      
    break
    
    default:
      buttonText.value = data.value.filter(elem => elem.value == props.options.defaultValue)[0].text;
      emit("pushData", { id: props.id, value: props.options.defaultValue });
    break
  }
}

function selectElement(event) {
  buttonText.value = event.target.outerText;
  emit("pushData", { id: props.id, value: event.target.value });
}
function clearElement() {
  buttonText.value = null;
  emit("pushData", { id: props.id, value: "" });
}
// Search part
function filterData(data) {
  filtredData.value = data;
}
function showSearchString() {
  if (props.searchbox) {
    if (data.value != undefined) {
      if (data.value.length != 0) {
        return true;
      }
    }
  }
  return false;
}
function showNoData() {

  if (data.value != undefined) {
    if (data.value.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

</script>

<template>
  <div class="mb-3" v-show="!props.hide">
    <label :for="id" class="form-label">{{ props.label }}</label>
    <span class="red-text" v-if="required">*</span>
    <i
      v-if="tooltip"
      :data-bs-html="true"
      style="color:#0d6efd"
      :data-bs-toggle=" tooltip === '' ? '' : 'tooltip'"
      data-bs-placement="top"
      :title="tooltip"
      class="bi bi-question-circle-fill m-2"
    ></i>
    <!-- <div class="input-group"> -->
    <div class="input-group" style="width: inherit;">
      <div class="dropdown d-grid gap-2 flex-fill" :id="id+'-dd'">
        <input
          :required="required"
          class="form-select form-control"
          v-model="buttonText"
          :value="buttonText"
          style="position: absolute; z-index: -1"
          :id="props.id"
          :placeholder="placeholder"
          :disabled="readonly"
        >
        <!-- <div class="input-group"> -->
        <button
          id="textButton"
          class="btn dropdown-toggle"
          :required="required"
          data-bs-display="static"
          type="text"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="width:auto; color:transparent"
          :disabled="readonly"
        >{{ buttonText }}</button>

        <ul class="dropdown-menu container">
          <div class="row">
            <searchBox
              :data="data"
              :id="props.id"
              @pushData="filterData($event)"
              v-show="showSearchString()"
            />
            <div v-show="showNoData()">
              <p
                class="ms-3 mb-0"
                v-if="props.hasOwnProperty('masterComponentValue') && data.value !== undefined"
              >Не выбрано значение зависимого поля {{data}}</p>
              <p class="ms-3 mb-0" v-else>Нет данных</p>
            </div>
          </div>
          <div class="row">
            <div class="col" v-if="filtredData">
              <li v-for="item in filtredData" :key="item.value">
                <button
                  class="dropdown-item"
                  @click.prevent="selectElement"
                  :value="item.value"
                >{{ item.text }}</button>
              </li>
            </div>
            <div class="col" v-else>Не заполненно зависимое поле</div>
          </div>
        </ul>
      </div>
      <button type="button" class="btn" @click.prevent="clearElement()" v-if="clearButton">
        <i class="bi bi-eraser-fill"></i>
      </button>
    </div>
    <div id class="form-text">{{ props.description }}</div>
  </div>
</template>

<style>
button#textButton {
  --bs-border-width: 1px;
  /* border: var(--bs-border-width) solid var(--bs-border-color); */
  border: transparent;
  text-align: left;
  border-radius: 7px;
}

button#textButton::after {
  border-top: 0em solid;
}
.tooltip {
  --bs-tooltip-max-width: auto;
}
.tooltip-inner {
  text-align: left;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
