import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"
// import LoginView from "../views/LoginView.vue"
import LoginView from "../views/LoginPage.vue"
import BalancesView from "../views/BalancesView.vue"
import CustomerView from "../views/CustomerView.vue"
import TicketView from "../views/TicketView.vue"
import TicketPage from "../views/TicketPage.vue"
import ReportsView from "../views/ReportsView.vue"
import ReportsBalanceCardsView from "../views/ReportsBalanceCardsView.vue"
import CreateTicketView from "../views/CreateTicketView.vue"
import CreateTicketPage from "../views/CreateTicketPage.vue"
import NewContractPage from "../views/NewContractPage.vue"
import ServiceCatalog from "../views/ServiceCatalogPage.vue"
import CatalogItemConstructor from "../views/FormConstructorPage.vue"
import ServiceCatalogCardPage from "../views/ServiceCatalogCardPage.vue"
import axios from 'axios'

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requireAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/balances",
    name: "balances",
    component: BalancesView,
    meta: { requireAuth: true },
  },
  {
    path: "/customer",
    name: "customer",
    component: CustomerView,
    meta: { requireAuth: true },
  },
  {
    path: "/ticket/:id",
    name: "ticket",
    component: TicketView,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/ticket2/:id",
    name: "ticket2",
    component: TicketPage,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsView,
    meta: { requireAuth: true },
  },
  {
    path: "/reports/balance-card",
    name: "ReportsBalanceCardsView",
    component: ReportsBalanceCardsView,
    meta: { requireAuth: true },
  },
  {
    path: "/create-ticket",
    name: "create-ticket",
    component: CreateTicketView,
    meta: { requireAuth: true },
  },
  {
    path: "/create-ticket-demo",
    name: "create-ticket-demo",
    component: CreateTicketPage,
    meta: { requireAuth: true },
  },
  {
    path: "/new-contract",
    name: "new-contract",
    component: NewContractPage,
    meta: { requireAuth: true },
  },
  {
    path: "/service-catalog",
    name: "service-catalog",
    component: ServiceCatalog,
    meta: { requireAuth: true },
  },
  {
    path: "/service-catalog/item/:name",
    name: "service-catalog-item",
    component: ServiceCatalogCardPage,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/catalog-item-constructor",
    name: "catalog-item-constructor",
    component: CatalogItemConstructor,
    meta: { requireAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// const verify_token = async (token) => {
// const url = `${process.env.VUE_APP_BASE_API}/api/v1/auth/verify-token`
// const config = {
//   headers:{
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`
//   }
// }
// console.log("verify-token")
// console.log(url)
// console.log(config)
// const response = await axios
//   .get(`${process.env.VUE_APP_BASE_API}/api/v1/auth/verify-token`,{
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   })


// .then(resp=>{
//   console.log(resp)
//   return true
// })
// .catch(e=>{
//   console.log("error")
//   console.log(e)
//   return false
// })

// var request = new XMLHttpRequest()
// request.open("GET", url, false) // `false` makes the request synchronous
// request.setRequestHeader("Content-Type", "application/json")
// request.setRequestHeader("Authorization", `Bearer ${token}`)
// request.send(null)

// if (request.status === 200) {
//   return true
// } else {
//   return false
// }
// }

router.auth = false

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
  console.log(to, from, next)
  const token = localStorage.getItem("token")
  if (token) {
    axios
      .get(`${process.env.VUE_APP_BASE_API}/api/v1/auth/verify-token`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(resp => {
        console.log("resp from Verify")
        console.log(resp)
        if(resp.status === 200){
          next()
        }
        else{
          next({
            path: "/login",
          })
        }
      })
      .catch(e => {
        console.log("error")
        console.log(e)
        next({
          path: "/login",
        })
      })
  } else {
    next({
      path: "/login",
    })
  }
}
else {
    next()
  }
})
// let token = localStorage.getItem("token")
// if (to.matched.some((record) => record.meta.requireAuth)) {
//   if (token && this.response) {
//     next()
//   } else {
//     next({
//       path: "/login",
//     })
//   }
// } else {
//   next()
// }
// })

export default router
